// customize Toastify as indicated in docs

.Toastify {
  &__toast {
    height: 72px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    gap: 32px;

    &--success {
      background: var(--success-color);
    }

    &--error {
      background: var(--error-color);
    }
  }

  &__toast-container {
    width: auto;
  }

  &__toast-body {
    color: var(--text-color);
  }
}
