/* Variables */

$fontFamily: 'Avenir', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto,
oxygen, oxygen-sans, ubuntu, cantarell, 'Fira Sans', 'Droid Sans',
'Helvetica Neue', helvetica, arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol';

:root {
  overscroll-behavior: none;
  --success-color: #b6eac9;
  --error-color: #ef7a7a;
  --toastify-icon-color-success: #2B5F3A;
  // Equivalent to theme.palette.common.black
  --text-color: black;


  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #000000 transparent;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    -webkit-appearance: none;
    -webkit-overflow-scrolling: auto;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
    border: 10px none transparent;
  }

}

/* Reset div line-heigth */
div {
  line-height: normal;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* DataTable icons */
.campaign-expiration-icon {
  margin: -4px 0 0 8px;
}

.rotate {
  transform: rotate(90deg); /* W3C */
  -webkit-transform: rotate(90deg); /* Safari & Chrome */
  -moz-transform: rotate(90deg); /* Firefox */
  -ms-transform: rotate(90deg); /* Internet Explorer */
  -o-transform: rotate(90deg); /* Opera */
}
